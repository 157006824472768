/**
 * Googlemap 2 Klick Lösung
 * @author Jolution
 */

( function() {
	"use strict";

//document.addEventListener("DOMContentLoaded", function(event) {

	var googlemap = document.querySelectorAll( ".googlemap-thumb" );

	for (var i = 0; i < googlemap.length; i++) {

		var playButton = googlemap[i].getElementsByClassName("googlemap-play-button")[0];

		playButton.addEventListener( "click", function() {

			var googlemapTarget = this.parentNode.parentNode,
				googlemapID = googlemapTarget.dataset.embed,
				googlemapURL = 'https://www.google.com/maps/embed?pb=' + googlemapID,
				wrapperWidth = googlemapTarget.dataset.width + "px",
				wrapperHeight = googlemapTarget.dataset.height + "px" || '100%';

			var iframe = document.createElement("IFRAME");
			iframe.setAttribute("src", googlemapURL);
			iframe.setAttribute("width", wrapperWidth);
			iframe.setAttribute("height", wrapperHeight);
			iframe.setAttribute("frameborder", "0");
			iframe.setAttribute("allowfullscreen", "");

			googlemapTarget.parentNode.replaceChild(iframe, googlemapTarget);

		});

	}

} )();



/**
 * Cookie Notice JS
 * @author Alessandro Benoit, Fork: Julian Ohlenschlaeger
 */

(function() {

    "use strict";

    /**
     * Store current instance
     */
    var instance;

    /**
     * Defaults values
     * @type object
     */
    var defaults = {
        'messageLocales': {
            //'it': 'Utilizziamo i cookie per essere sicuri che tu possa avere la migliore esperienza sul nostro sito. Se continui ad utilizzare questo sito assumiamo che tu ne sia felice.',
            //'en': 'We use cookies to make sure you can have the best experience on our website. If you continue to use this site we assume that you will be happy with it.',
            'de': 'Cookies erleichtern die Bereitstellung unserer Dienste. Mit der Nutzung unserer Dienste erklären Sie sich damit einverstanden, dass wir Cookies verwenden. <a style="color: #fff;" href="/datenschutz">Mehr Infos</a>',
            //'fr': 'Nous utilisons des cookies afin d\'être sûr que vous pouvez avoir la meilleure expérience sur notre site. Si vous continuez à utiliser ce site, nous supposons que vous acceptez.'
        },
        'buttonLocales': {
            'de': 'Einverstanden'
        },
        'expiresIn': 14,
        'buttonBgColor': '#292c2f',
        'buttonTextColor': '#fff',
        'noticeBgColor': '#f5f5f5',
        'noticeTextColor': '#292c2f'
    };

    function pushDown() {
        //var footer = document.getElementsByClassName("footer")[0];
        var footer = document.getElementById("main");

        if ( document.getElementById("main") != null && document.getElementById("cookieNotice") != null ) {
          var cookieBar = document.getElementById("cookieNotice");
          var height = parseInt(getComputedStyle(cookieBar).height);
        }else{
          var cookieBar = false;
          var height = "0";
          removePushDown();
        }

        footer.style.marginBottom = height + "px";
        //footer.setAttribute("style", "margin-bottom: "+ height +"px");
    }

    function initPushDown() {
        pushDown();
        window.addEventListener("resize", pushDown);
    }

    function removePushDown() {
        //var footer = document.getElementsByClassName("footer")[0];
        var footer = document.getElementById("main");
        footer.style.marginBottom = 0;
        window.removeEventListener("resize", pushDown);
    }

    /**
     * Initialize cookie notice on DOMContentLoaded
     * if not already initialized with alt params
     */
    document.addEventListener('DOMContentLoaded', function() {
        if (!instance) {
            new cookieNoticeJS();
            initPushDown();
        }
    });

    /**
     * Constructor
     * @constructor
     */
    window.cookieNoticeJS = function() {

        // If an instance is already set stop here
        if (instance !== undefined) {
            return;
        }

        // Set current instance
        instance = this;

        // If cookies are not supported or notice cookie is already set
        if (!testCookie() || getNoticeCookie()) {
            return;
        }

        // Extend default params
        var params = extendDefaults(defaults, arguments[0] || {});

        // Get current locale for notice text
        var noticeText = getStringForCurrentLocale(params.messageLocales);

        // Create notice
        var notice = createNotice(noticeText, params.noticeBgColor, params.noticeTextColor);

        // Get current locale for button text
        var buttonText = getStringForCurrentLocale(params.buttonLocales);

        // Create dismiss button
        var dismissButton = createDismissButton(buttonText, params.buttonBgColor, params.buttonTextColor);

        // Dismiss button click event
        dismissButton.addEventListener('click', function(e) {
            e.preventDefault();
            setDismissNoticeCookie(parseInt(params.expiresIn + "", 10) * 60 * 1000 * 60 * 24);
            fadeElementOut(notice);
        });

        // Append notice to the DOM
        document.body.appendChild(notice).appendChild(dismissButton);

    };

    /**
     * Get the string for the current locale
     * and fallback to "en" if none provided
     * @param locales
     * @returns {*}
     */
    function getStringForCurrentLocale(locales) {
        var locale = (document.documentElement.lang || navigator.userLanguage || navigator.language).substr(0, 2);
        return (locales[locale]) ? locales[locale] : locales['de'];
    }

    /**
     * Test if cookies are enabled
     * @returns {boolean}
     */
    function testCookie() {
        document.cookie = 'testCookie=1';
        return document.cookie.indexOf('testCookie') != -1;
    }

    /**
     * Test if notice cookie is there
     * @returns {boolean}
     */
    function getNoticeCookie() {
        return document.cookie.indexOf('cookie_notice') != -1;
    }

    /**
     * Create notice
     * @param message
     * @param bgColor
     * @param textColor
     * @returns {HTMLElement}
     */
    function createNotice(message, bgColor, textColor) {

        var notice = document.createElement('div'),
            noticeStyle = notice.style;

        notice.innerHTML = message;
        notice.setAttribute('id', 'cookieNotice');

        noticeStyle.position = 'fixed';
        noticeStyle.bottom = 0;
        noticeStyle.left = 0;
        noticeStyle.right = 0;
				//noticeStyle.borderTop = "4px solid rgb(68, 68, 68)";
        noticeStyle.background = bgColor;
        noticeStyle.color = textColor;
        noticeStyle["z-index"] = '99999999';
        noticeStyle.padding = '10px 5px';
        noticeStyle["text-align"] = 'center';
        noticeStyle["font-size"] = "12px";
        noticeStyle["line-height"] = "28px";
        noticeStyle.fontFamily = 'Helvetica neue, Helvetica, sans-serif';

        return notice;
    }

    /**
     * Create dismiss button
     * @param message
     * @param buttonColor
     * @param buttonTextColor
     * @returns {HTMLElement}
     */
    function createDismissButton(message, buttonColor, buttonTextColor) {

        var dismissButton = document.createElement('a'),
            dismissButtonStyle = dismissButton.style;

        // Dismiss button
        dismissButton.href = '#';
        dismissButton.innerHTML = message;

        // Dismiss button style
        dismissButtonStyle.background = buttonColor;
        dismissButtonStyle.color = buttonTextColor;
        dismissButtonStyle['text-decoration'] = 'none';
        dismissButtonStyle.display = 'inline-block';
        dismissButtonStyle.padding = '0 15px';
        dismissButtonStyle.margin = '0 0 0 10px';

        return dismissButton;

    }

    /**
     * Set sismiss notice cookie
     * @param expireIn
     */
    function setDismissNoticeCookie(expireIn) {
        var now = new Date(),
            cookieExpire = new Date();

        cookieExpire.setTime(now.getTime() + expireIn);
        document.cookie = "cookie_notice=1; expires=" + cookieExpire.toUTCString() + "; path=/;";
    }

    /**
     * Fade a given element out
     * @param element
     */
    function fadeElementOut(element) {
        element.style.opacity = 1;
        (function fade() {
            (element.style.opacity -= .1) < 0.01 ? document.body.removeChild(element) : setTimeout(fade, 40)
        })();
        removePushDown();
    }

    /**
     * Utility method to extend defaults with user options
     * @param source
     * @param properties
     * @returns {*}
     */
    function extendDefaults(source, properties) {
        var property;
        for (property in properties) {
            if (properties.hasOwnProperty(property)) {
                if (typeof source[property] === 'object') {
                    source[property] = extendDefaults(source[property], properties[property]);
                } else {
                    source[property] = properties[property];
                }
            }
        }
        return source;
    }

    /* test-code */
    cookieNoticeJS.extendDefaults = extendDefaults;
    cookieNoticeJS.clearInstance = function() {
        instance = undefined;
    };
    /* end-test-code */


}());
